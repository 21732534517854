import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import { SURVEYS_TYPES, AUTH_MATCH_TYPES } from "./types";
import "./index.css";
import { Loader, PageContainer } from "./components";
import {
  QuizPage,
  CabinetPage,
  BlogPage,
  ProfilePage,
  AppAuthPage,
  NewPostPage,
  CoursesPage,
  CalendarPage,
  NewCoursePage,
  RegistrationPage,
  RecoverPage,
  ClinicLandingPage,
  Post,
  SubscriptionPage,
  PaymentPage,
  RemoveAccountPage,
} from "./pages";

const LandingPage = React.lazy(() => import("./pages/landing"));

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path='/' element={<LandingPage />} />

      <Route path='/clinic-promo' element={<ClinicLandingPage />} />

      <Route path='/survey' element={<QuizPage />}>
        <Route
          id={SURVEYS_TYPES.CARDIO}
          path={SURVEYS_TYPES.CARDIO}
          element={<QuizPage />}
        />
        <Route
          id={SURVEYS_TYPES.THERAPY}
          path={SURVEYS_TYPES.THERAPY}
          element={<QuizPage />}
        />
      </Route>

      <Route path='/auth' element={<AppAuthPage />}>
        <Route
          id={AUTH_MATCH_TYPES.RESULTS}
          path={AUTH_MATCH_TYPES.RESULTS}
          element={<AppAuthPage />}
        />
      </Route>
      <Route path='/registration' element={<RegistrationPage />} />

      <Route path='/subscription' element={<SubscriptionPage />} />
      <Route path='/payment/complete' element={<PaymentPage />} />

      <Route path='/api' element={<AppAuthPage />}>
        <Route id='auth' path='auth' element={<AppAuthPage />}>
          <Route
            id={AUTH_MATCH_TYPES.CONFIRM}
            path={AUTH_MATCH_TYPES.CONFIRM}
            element={<AppAuthPage />}
          />
        </Route>
      </Route>

      <Route path='/recover' element={<RecoverPage />} />

      <Route path='/profile' element={<ProfilePage />} />
      <Route path='/profile/cabinet' element={<CabinetPage />} />
      <Route path='/profile/course' element={<CoursesPage />} />
      <Route path='/profile/course/new' element={<NewCoursePage />} />

      <Route path='/remove-account' element={<RemoveAccountPage />} />

      <Route path='/profile/calendar' element={<CalendarPage />} />

      <Route path='blog' element={<BlogPage />} />
      <Route path='blog/:id' id=':id' element={<Post />} />
      <Route path='newpost' element={<NewPostPage />} />

      {/* <Route path="medcard" element={<MedCardPage />} />
      <Route path="medcard/new" element={<NewAppointmentPage />} />
      <Route path="medcard/:cardId" id="cardId" element={<AppointmentPage />} /> */}
    </>
  )
);

function App() {
  return (
    <Suspense
      fallback={
        <div className='suspense'>
          <Loader />
        </div>
      }
    >
      <RouterProvider
        router={router}
        fallbackElement={<PageContainer>loading...</PageContainer>}
      />
    </Suspense>
  );
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(<App />);
