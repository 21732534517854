import { styled } from 'styled-components';
import { COLORS } from '../../const';

import backgroundPath from '../../assets/background2.png';
import backgroundSmallPath from '../../assets/background.png';
import vector1Path from '../../assets/vector1.png';
import vector2Path from '../../assets/vector2.svg';
import vector3Path from '../../assets/vector3.png';

export const LandingContainer = styled.div`
  position: relative;
  width: 100vw;
  background: url(${backgroundPath});
  background-repeat: no-repeat;
  background-size: 100% 5000px;
  background-position-x: center;
  overflow-x: hidden;

  @media screen and (max-width: 1024px) {
    max-width: 100%;
    background: url(${backgroundSmallPath});
    background-size: 100%;
    background-position-y: -10px;
    background-repeat: no-repeat;

  @media screen and (max-width: 820px) {
    max-width: 100%;
    background: none;
  }
`;

export const LandingWrapper = styled.div`
  margin: 0 auto;
  max-width: 1200px;
`;

export const PageContainer = styled.div`
  position: relative;
  max-width: 100vw;
`;

// HEADER

export const HeaderContainer = styled.div`
  width: 100%;
  padding-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 820px) {
    padding-top: 8px;
  }
`;

export const LogoWrapper = styled.div`
  margin-right: 135px;
  padding-top: 12px;

  @media screen and (max-width: 1024px) {
    margin-right: 40px;
  }

  @media screen and (max-width: 820px) {
    margin-right: 0px;
    margin-left: 16px;
  }
`;

export const SignInButton = styled.div`
  width: fit-content;
  padding: 12px 54px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  background: #68c378;
  font-size: 16px;
  font-weight: 700;
  font-family: 'Inter', sans-serif;
  color: #fff;

  @media screen and (max-width: 820px) {
    padding: 6px 33px;
  }
`;

export const MenuContainer = styled.div`
  margin-left: 135px;
  display: flex;
  align-items: center;

  & a + a {
    margin-left: 32px;
  }

  @media screen and (max-width: 1024px) {
    margin-left: 40px;
  }

  @media screen and (max-width: 820px) {
    display: none;
  }
`;

export const MobileHeader = styled.div`
  display: none;

  @media screen and (max-width: 820px) {
    margin-right: 16px;
    display: flex;
  }
`;

// COMMON

export const BlockTitleWrapper = styled.div`
  position: relative;
`;

export const BlockTitle = styled.a`
  font-size: 44px;
  font-weight: 700;
  font-family: 'Inter', sans-serif;
  line-height: 50px;
  color: #35373f;

  @media screen and (max-width: 820px) {
    font-size: 24px;
    line-height: 30px;
  }
`;

export const LightBlockTitle = styled(BlockTitle)`
  color: #f5f5f5;

  &:hover {
    color: #f5f5f5;
  }
`;

export const BlockItem = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;

  & span {
    margin: 24px 0px;

    @media screen and (max-width: 910px) {
      margin: 21px 0px;
    }
  }

  & img {
    @media screen and (max-width: 910px) {
      width: 100%;
    }
  }

  @media screen and (max-width: 910px) {
    margin-bottom: 23px;
  }
`;

export const Divider = styled.div`
  width: 100%;
  height: 3px;
  background: linear-gradient(
    90deg,
    rgba(93, 175, 108, 0) 0.37%,
    #5daf6c 51.18%,
    rgba(93, 175, 108, 0) 100%
  );

  @media screen and (max-width: 820px) {
    display: none;
  }
`;

// WELCOME BLOCK

export const LogoTitle = styled.div`
  position: absolute;
  bottom: -16px;
  left: 40px;

  @media screen and (max-width: 820px) {
    bottom: -10px;
    left: 20px;
  }

  & img {
    width: 180px;

    @media screen and (max-width: 820px) {
      width: 98px;
    }
  }
`;

export const WelcomeWrapper = styled.div`
  position: relative;
  margin: 84px 135px 0 135px;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 1024px) {
    margin: 84px 40px 0 40px;
  }

  @media screen and (max-width: 820px) {
    margin: 32px 16px 0 16px;
    background: url(${vector2Path});
    background-repeat: no-repeat;
    background-size: contain;
    background-position-y: top;
    background-position-x: center;
  }
`;

export const WelcomeBlockContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  @media screen and (max-width: 820px) {
    flex-direction: column-reverse;
    align-items: center;
    gap: 0;
  }
`;

export const WelcomeBlockItem = styled.div`
  max-width: 421px;
  padding-top: 162px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media screen and (max-width: 820px) {
    padding: 100px 16px 0 16px;
  }
`;

export const WelcomeBlockTitleWrapper = styled(BlockTitleWrapper)`
  position: absolute;
  top: 32px;
  width: 437px;

  @media screen and (max-width: 820px) {
    top: 250px;
  }
`;

export const WelcomeBlockImage = styled.div`
  max-width: 354px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 820px) {
    width: 260px;
    height: 224px;
  }

  & svg {
    @media screen and (max-width: 820px) {
      width: 100%;
      object-fit: cover;
    }
  }
`;

export const ButtonsWrapper = styled.div`
  padding-top: 36px;
  padding-bottom: 35px;
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 820px) {
    gap: 16px;
    flex-direction: column;
    align-items: center;
  }
`;

export const Button = styled.a`
  width: 200px;
  height: 52px;
  padding: 14px 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  background: #000000;

  @media screen and (max-width: 820px) {
    width: 288px;
  }
`;

// ADVANTAGES

export const AdvantagesContainer = styled.div`
  margin: 32px 135px 0 135px;

  @media screen and (max-width: 820px) {
    margin: 0;
    background: url(${vector1Path});
    background-repeat: no-repeat;
    background-size: cover;
    background-position-y: top;
    background-position-x: center;
  }
`;

export const AdvantagesRows = styled.div`
  padding-top: 43px;
  display: flex;
  flex-direction: column;
  gap: 120px;

  @media screen and (max-width: 1024px) {
    gap: 190px;
  }
`;

export const AdvantageRow = styled.div`
  position: relative;
  height: 500px;
  display: flex;

  @media screen and (max-width: 1024px) {
    height: 320px;
  }
`;

export const AdvantageTitle = styled.div`
  font-size: 36px;
  font-weight: 700;
  line-height: 48px;

  @media screen and (max-width: 1024px) {
    font-size: 24px;
    font-weight: 600;
    line-height: 30px;
  }
`;

export const AdvantageText = styled.div`
  max-width: 545px;
  padding-top: 32px;
  display: flex;
  flex-direction: column;
  gap: 32px;

  @media screen and (max-width: 1024px) {
    padding-top: 0px;
  }
`;

export const AdvantageTextRight = styled.div`
  max-width: 545px;
  padding-top: 32px;
  margin-left: 420px;
  display: flex;
  flex-direction: column;
  gap: 32px;

  @media screen and (max-width: 1024px) {
    padding-top: 0px;
    margin-left: 300px;
  }
`;

export const AdvantageImageRight = styled.div`
  position: absolute;
  top: 0;
  right: 0;

  & img {
    height: 500px;

    @media screen and (max-width: 1024px) {
      height: 320px;
    }
  }
`;

export const AdvantageImageLeft = styled.div`
  position: absolute;
  top: 0;
  left: 0;

  & img {
    height: 500px;

    @media screen and (max-width: 1024px) {
      height: 320px;
    }
  }
`;

export const MobileAdvantagesContainer = styled.div`
  margin: 0 48px;
  padding-top: 70px;
  padding-bottom: 32px;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 768px) {
    margin: 0;
    flex-direction: column;
    align-items: center;
  }
`;

export const MobileAdvantagesWrapper = styled.div`
  padding-top: 32px;
  display: flex;
  gap: 48px;

  @media screen and (max-width: 768px) {
    padding: 0;
    margin-top: 16px;
    flex-direction: column;
    gap: 0;
  }
`;

export const MobileAdvantagesBlockItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  & div {
    margin-bottom: 30px;
    display: flex;
    align-items: center;

    & p {
      font-size: 14px;
      font-weight: 600;
      color: #fff;
    }

    & img {
      width: 48px;
      height: 48px;
      margin-right: 16px;

      @media screen and (max-width: 768px) {
        width: 32px;
        height: 32px;
      }
    }
  }
`;

// FOR WHOM

export const ForWhomBlockContainer = styled.div`
  margin: 250px 70px 0 90px;
  display: flex;
  justify-content: space-between;
  gap: 60px;

  @media screen and (max-width: 1024px) {
    margin: 160px 30px 0px 30px;
  }

  @media screen and (max-width: 820px) {
    margin: 0;
    padding: 48px 40px 0 40px;
    flex-direction: column;
    gap: 0;
    background: url(${vector2Path});
    background-repeat: no-repeat;
    background-size: contain;
    background-position-y: top;
    background-position-x: center;
  }

  @media screen and (max-width: 768px) {
    padding: 40px 30px 0 30px;
  }
`;
export const ForWhomImage = styled(BlockItem)`
  justify-content: flex-start;

  @media screen and (max-width: 820px) {
    justify-content: center;
    align-items: center;
  }

  @media screen and (max-width: 768px) {
    margin: 0 32px;
  }

  & img {
    max-width: 476px;
  }
`;

export const ForWhomText = styled(BlockItem)`
  padding-top: 42px;

  @media screen and (max-width: 768px) {
    margin: 0px;
    padding-top: 32px;
  }
`;

// WHY BLOCK

export const WhyBlockContainer = styled.div`
  margin: 128px 48px 0 135px;
  display: flex;
  justify-content: space-between;
  color: #f5f5f5;
  gap: 50px;

  @media screen and (max-width: 1024px) {
    margin: 0;
    padding: 100px 40px 0 40px;
  }

  @media screen and (max-width: 820px) {
    margin: 0;
    padding: 40px 30px 60px 30px;
    flex-direction: column-reverse;
    gap: 0;
    background: url(${vector3Path});
    background-repeat: no-repeat;
    background-size: cover;
    background-position-y: top;
    background-position-x: center;
  }
`;

// DEVELOPING

export const DevelopingContainer = styled.div`
  position: relative;
  margin: 152px 135px 0 135px;
  display: flex;
  gap: 24px;

  @media screen and (max-width: 820px) {
    margin: 32px 30px 0px 30px;
    flex-direction: column;
  }
`;

export const DevelopingWrapper = styled.div`
  flex-basis: 50%;
  display: flex;
  flex-direction: column;
  gap: 130px;

  @media screen and (max-width: 820px) {
    gap: 32px;
  }
`;

export const GreyTitle = styled.p`
  padding: 28px 0 20px;
  font-size: 44px;
  line-height: 50px;
  font-weight: 700;
  color: #9a9b9f;

  @media screen and (max-width: 1024px) {
    padding: 0;
  }

  @media screen and (max-width: 820px) {
    font-size: 24px;
    line-height: 30px;
    color: ${COLORS.text_dark};
  }
`;

export const DevelopingItem = styled.div`
  display: flex;
  gap: 16px;
`;

export const DevelopingImg = styled.div`
  position: absolute;
  width: 100%;
  top: 150px;

  & img {
    width: 100%;
    object-fit: contain;
  }

  @media screen and (max-width: 820px) {
    display: none;
  }
`;

export const FlagImg = styled.div`
  display: none;

  @media screen and (max-width: 820px) {
    padding-top: 8px;
    display: flex;
    align-items: flex-start;
  }
`;

export const FooterButtons = styled.div`
  margin: 64px 135px 32px 135px;
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 820px) {
    gap: 16px;
    flex-direction: column;
    align-items: center;
  }
`;

// FOOTER

export const FooterContainer = styled.div`
  width: 100%;
  padding-top: 64px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(90deg, #147a54 20%, #0b4831 80%);
`;

export const FooterWrapper = styled.div`
  width: 941px;
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 820px) {
    padding: 0 30px;
    width: 100%;
    flex-direction: column-reverse;
  }
`;

export const Bottom = styled.div`
  width: 100%;
  padding: 18px 0;
  display: flex;
  justify-content: center;
  border-top: 1px solid ${COLORS.main_grey1};

  @media screen and (max-width: 820px) {
    width: 100%;
    padding: 18px;
  }
`;

export const FooterInfo = styled.div`
  padding-bottom: 32px;
  display: flex;
  flex-direction: column;
  gap: 8px;

  @media screen and (max-width: 820px) {
    padding-top: 16px;
  }
`;

export const FooterLinks = styled.div`
  display: flex;
  gap: 34px;

  @media screen and (max-width: 820px) {
    display: none;
  }
`;

export const Copyright = styled.div`
  display: flex;
  align-items: center;

  @media screen and (max-width: 700px) {
    justify-content: center;
  }
`;
