import { styled } from "styled-components";

import backgroundPath from "../../assets/App.png";

export const LandingContainer = styled.div`
  position: relative;
  width: 100vw;
  padding-bottom: 86px;
  background: url(${backgroundPath});
  background-repeat: no-repeat;
  background-size: 50%;
  background-position-x: center;
  overflow-x: hidden;

  @media screen and (max-width: 820px) {
    max-width: 100%;
    padding-bottom: 32px;
    background-size: 150%;
  }
`;

export const Header = styled.div`
  max-width: 930px;
  margin: 16px auto 87px;

  @media screen and (max-width: 1024px) {
    max-width: 100%;
    margin: 16px 16px 32px;
  }
`;

export const Divider = styled.div`
  width: 100%;
  max-width: 930px;
  height: 3px;
  margin: 32px auto;
  background: linear-gradient(
    90deg,
    rgba(93, 175, 108, 0) 0.37%,
    #5daf6c 51.18%,
    rgba(93, 175, 108, 0) 100%
  );

  @media screen and (max-width: 820px) {
    max-width: 90%;
    height: 1;
    margin: 16px auto;
  }
`;

export const InviteWrapper = styled.div`
  margin: 0 auto;
  max-width: 686px;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 820px) {
    max-width: 100%;
    margin-bottom: 32px;
    padding: 0 16px;
  }
`;

export const Title = styled.h1`
  font-size: 32px;
  line-height: 38px;
  font-weight: 700;
  text-align: center;

  @media screen and (max-width: 1024px) {
    font-size: 24px;
    line-height: 29px;
  }
`;

export const Subtitile = styled.h2`
  margin: 32px 0;
  font-size: 24px;
  line-height: 29px;
  font-weight: 600;

  @media screen and (max-width: 1024px) {
    margin: 16px 0;
    font-size: 18px;
    line-height: 22.5px;
  }
`;

export const Text = styled.p`
  font-size: 18px;
  line-height: 25px;

  @media screen and (max-width: 1024px) {
    font-size: 14px;
    line-height: 21px;
  }
`;
