import { Text, LogoIcon } from "../../components";
import {
  InviteWrapper,
  LandingContainer,
  Divider,
  Header,
  Title,
  Subtitile,
} from "./styled";

import { Footer } from "../landing/Footer";

export const RemoveAccountPage = () => {
  return (
    <div style={{ minHeight: "100vh" }}>
      <LandingContainer>
        <Header>
          <LogoIcon />
        </Header>

        <InviteWrapper>
          <Title>Инструкция по удалению аккаунта в приложении Doctorly:</Title>

          <Subtitile>1. Авторизация:</Subtitile>
          <Text>
            Войдите в свою учетную запись, используя текущие данные для входа.
          </Text>

          <Subtitile>2. Доступ к профилю:</Subtitile>
          <Text> После авторизации, перейдите в раздел “Профиль”.</Text>

          <Subtitile>3. Редактирование профиля:</Subtitile>
          <Text>В разделе “Профиль” выберите опцию “Редактировать”.</Text>

          <Subtitile>4. Удаление аккаунта:</Subtitile>
          <Text>
            Найдите и нажмите кнопку “Удалить аккаунт”.
            <br />
            Вам будет предложено подтвердить свое намерение удалить учетную
            запись.
          </Text>
        </InviteWrapper>

        <Divider />

        <InviteWrapper>
          <Text>
            После выполнения всех этих шагов ваш аккаунт в приложении Doctorly
            будет удален.
          </Text>
          <br />
          <Text>
            Обратите внимание, что удалить аккаунт с активной премиум подпиской
            не получится. Аккаунт будет удален по завершении оплаченного
            периода.
          </Text>
          <br />
          <Text>
            Этот процесс необратим, и вы потеряете доступ ко всем данным и
            услугам, связанным с этой учетной записью.
          </Text>
        </InviteWrapper>
      </LandingContainer>
      <Footer />
    </div>
  );
};
